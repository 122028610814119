export default function DistributorsPage() {
  return (
    <>
      <h1>Distributors</h1>
      <p>
        We have varied ecommerce projects, and we would liket to invite you to
        join our team and share our resource.
      </p>
      <p>
        With the partnership with us, you will get much better progress over
        time.
      </p>
      <p>Checkout some of our projects below</p>
      <ul>
        <li>
          <a href="https://vt.tiktok.com/ZTNsJjVJ6/?page=TikTokShop">TikTok</a>
        </li>
      </ul>
      <p>Shooting us email at dkwholesale2020@gmail.com to chat.</p>
    </>
  );
}
